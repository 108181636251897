import { DateTime } from "luxon";

import { ApiReportBreak, ApiReportDay, ApiReportPresence, ReportBreak, ReportDay, ReportPresence } from "./types";

export function groupDaysByWeeks(days: ReportDay[]): Record<string, ReportDay[]> {
    return days.reduce<Record<string, ReportDay[]>>((acc, day) => {
        const weekDate = day.date.startOf("week").toISODate();
        if (!acc[weekDate]) {
            acc[weekDate] = [];
        }
        acc[weekDate].push(day);
        return acc;
    }, {});
}

export function transformDay(day: ApiReportDay): ReportDay {
    return {
        date: DateTime.fromISO(day.date),
        presences: day.presences.map(transformPresence),
        presenceHours: parseFloat(day.presence_hours),
        breakHours: parseFloat(day.break_hours),
        workHours: parseFloat(day.work_hours)
    };
}

function transformPresence(apiReportPresence: ApiReportPresence): ReportPresence {
    return {
        startTime: extractTimeFromDateTimeString(apiReportPresence.starts_at),
        endTime: extractTimeFromDateTimeString(apiReportPresence.ends_at),
        breaks: apiReportPresence.breaks.map(transformReportBreak),
        workHours: parseFloat(apiReportPresence.work_hours),
        breakHours: parseFloat(apiReportPresence.break_hours),
        presenceHours: parseFloat(apiReportPresence.presence_hours)
    };
}

function extractTimeFromDateTimeString(dateTimeString: string) {
    const date = new Date(dateTimeString);
    // Set the options to use UTC time zone
    const options: Intl.DateTimeFormatOptions = {
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Europe/Berlin"
    };
    return date.toLocaleTimeString("de-DE", options);
}

function transformReportBreak(apiReportBreak: ApiReportBreak): ReportBreak {
    return {
        startTime: extractTimeFromDateTimeString(apiReportBreak.starts_at),
        endTime: extractTimeFromDateTimeString(apiReportBreak.ends_at),
        breakHours: parseFloat(apiReportBreak.break_hours)
    };
}

